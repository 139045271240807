<template>
  <vs-row>
    <!-- <vs-col vs-w="12">
      <bot-logs-stream-navbar
        :bot="$route.query.bot"
        :serviceId="$route.query.serviceId"
        :botName="$route.query.botName"
      />
    </vs-col>-->

    <vs-col class="sm:w-1/1 md:w-1/1 lg:w-1/1 xl:w-1/1">
      <div :class="{ maximize: maximizeLogsStreamContainer }">
        <div
          class="vx-row"
          :class="{
            'scroll-area': !maximizeLogsStreamContainer,
            'scroll-area-maximized': maximizeLogsStreamContainer
          }"
        >
          <vs-col
            :vs-w="showFilters ? '9' : '12'"
            :style="{ padding: maximizeLogsStreamContainer ? 0 : undefined }"
          >
            <VuePerfectScrollbar
              :class="{
                'scroll-area': !maximizeLogsStreamContainer,
                'scroll-area-maximized': maximizeLogsStreamContainer
              }"
              :key="key"
              class="logs"
              :settings="settings"
              ref="scroll"
              @ps-y-reach-end="updateAutoScroll(true)"
              @ps-scroll-up="updateAutoScroll(false)"
            >
              <div class="float-right max-min-icon">
                <feather-icon
                  v-tooltip.left="
                    maximizeLogsStreamContainer ? 'Minimizar' : 'Maximizar'
                  "
                  :icon="
                    maximizeLogsStreamContainer
                      ? 'Minimize2Icon'
                      : 'Maximize2Icon'
                  "
                  class="cursor-pointer text-white float-right"
                  @click="maximize"
                ></feather-icon>
                <feather-icon
                  v-tooltip.left="
                    showFilters ? 'Ocultar filtros' : 'Mostrar filtros'
                  "
                  :icon="'FilterIcon'"
                  :badge="
                    Object.keys(filters.level).reduce(
                      (acc, key) => (filters.level[key] ? acc + 1 : acc),
                      0
                    ) +
                      (!searchInput || searchInput.trim().length === 0 ? 0 : 1)
                  "
                  class="cursor-pointer text-white float-right mt-4"
                  @click="showFilters = !showFilters"
                ></feather-icon>
              </div>
              <div class="float-left">
                <div :key="key" v-for="(log, key) in filteredLogs">
                  <vs-divider position="left">{{ log.timestamp }}</vs-divider>
                  <span v-dompurify-html="`${$ansiHTML(log.level)} ${log.label}`"></span>:
                  <pre>{{ log.message }}</pre>
                </div>
              </div>
            </VuePerfectScrollbar>
          </vs-col>
          <vs-col v-if="showFilters" vs-w="3" class="filters">
            <header class="vs-collapse-item--header m-0">
              <div data-v-25390af8 class="text-primary">Filtros</div>
            </header>
            <vs-divider class="mt-0 mb-2 mr-6" />
            <div class="ml-6 mr-6 mt-2">
              <vs-input
                id="searchh"
                icon="icon-search"
                icon-pack="feather"
                list="browsers"
                class="
                  input-rounded-full
                  no-icon-border
                  search-input
                  w-full
                  mt-base
                "
                :placeholder="'senderId'"
                v-model="searchInput"
              />
            </div>
            <div class="ml-4 mr-4 mt-4">
              <vs-list>
                <vs-list-header title="Level"></vs-list-header>
                <vs-list-item subtitle="Info" class="p-4 pr-0 pl-1 text-dark">
                  <vs-checkbox
                    color="primary"
                    v-model="filters.level.info"
                    style="margin-right: 6px"
                  ></vs-checkbox>
                </vs-list-item>
                <vs-list-item subtitle="Error" class="p-4 pr-0 pl-1 text-dark">
                  <vs-checkbox
                    color="danger"
                    v-model="filters.level.error"
                    style="margin-right: 6px"
                  ></vs-checkbox>
                </vs-list-item>
              </vs-list>
            </div>
            <div class="ml-4 mr-4 mt-2">
              <vs-list>
                <vs-list-header title="Label"></vs-list-header>
                <vs-list-item
                  :key="key"
                  v-for="(item, key) in labels"
                  :subtitle="item.label"
                  class="p-2 text-dark"
                >
                  <vs-chip color>{{ item.count }}</vs-chip>
                </vs-list-item>
              </vs-list>
            </div>
          </vs-col>
        </div>
      </div>
    </vs-col>
  </vs-row>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ansiHTML from 'k-ansi-html'
Object.defineProperty(Vue.prototype, '$ansiHTML', { value: ansiHTML })

export default {
  name: 'BotLogsStream',
  components: {
    VuePerfectScrollbar
  },
  data() {
    return {
      key: new Date().getTime(),

      logs: [],
      settings: {},
      autoScroll: true,

      searchInput: null,
      showFilters: false,

      filters: {
        level: {
          info: true,
          error: true
        }
      }
    }
  },
  methods: {
    ...mapMutations(['MAXIMIZE_LOGS_STREAM_CONTAINER']),
    maximize() {
      this.MAXIMIZE_LOGS_STREAM_CONTAINER()
      this.key = new Date().getTime()
      setTimeout(() => {
        const container = this.$refs.scroll.$el
        container.scrollTop = container.scrollHeight
        this.$refs.scroll.$el.update()
      }, 0)
    },
    join(bot) {
      const session = localStorage.getItem('session')
      const token = JSON.parse(session).token
      this.$socket.client.emit('join', {
        type: 'log',
        token,
        bot
      })
    },
    leave(bot) {
      const session = localStorage.getItem('session')
      const token = JSON.parse(session).token
      this.$socket.client.emit('leave', {
        type: 'log',
        token,
        bot
      })
    },
    updateAutoScroll(val) {
      this.autoScroll = val
    },
    // },
    // sockets: {
    log(data) {
      this.logs.push(data)
      if (this.autoScroll) {
        setTimeout(() => {
          const container = this.$refs.scroll.$el
          container.scrollTop = container.scrollHeight
          this.$refs.scroll.$el.update()
        }, 0)
      }
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'maximizeLogsStreamContainer']),
    filteredLogs() {
      const levels = Object.keys(this.filters.level).filter(
        key => this.filters.level[key]
      )
      if (!this.searchInput || this.searchInput.trim().length === 0) {
        return this.logs.filter(
          item =>
            levels.indexOf(this.$ansiHTML(item.level).split(/<.*?>/)[1]) > -1
        )
      }
      return this.logs
        .filter(
          item => item.senderId && item.senderId.includes(this.searchInput)
        )
        .filter(
          item =>
            levels.indexOf(this.$ansiHTML(item.level).split(/<.*?>/)[1]) > -1
        )
    },
    labels() {
      const aux = this.filteredLogs.reduce((acc, item) => {
        if (acc[item.label] === undefined) {
          acc[item.label] = 0
        }
        acc[item.label] += 1
        return acc
      }, {})
      return Object.keys(aux).map(key => ({ label: key, count: aux[key] }))
    }
  },
  mounted() {
    setTimeout(() => {
      this.join(this.$route.query.bot)
      this.$socket.client.on('log', this.log)
      this.logs = [
        {
          level: '\u001b[32minfo\u001b[39m',
          label: 'logs',
          message: this.lang.botLogsStream.readyMessage[this.languageSelected],
          timestamp: new Date()
        }
      ]
    }, 1500)
  },
  beforeDestroy() {
    this.$socket.client.off('log')
    this.leave(this.$route.params.bot)
    if (this.maximizeLogsStreamContainer) {
      this.MAXIMIZE_LOGS_STREAM_CONTAINER()
    }
  }
}
</script>

<style scoped>
.logs {
  background: #13192a;
  color: white;
  padding-right: 1em;
  padding-left: 1em;
  padding-bottom: 1em;
}
.scroll-area {
  margin: auto;
  width: 100%;
  height: 70vh;
}
.scroll-area-maximized {
  margin: auto;
  width: 100%;
  height: 100vh;
}
.maximize {
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 50000;
  margin-left: 0;
  width: 100%;
  height: calc(100vh);
  transition: 0.5s;
}
.max-min-icon {
  width: 20px !important;
  position: sticky !important;
  top: 1em;
  right: 0em;
  z-index: 99999;
}
.filters {
  background: #f8f8f8;
}
</style>
